<template>
  <div>
    <UploadList
      v-model="uploadData"
      :disabled="disabled"
      fileCode="user_fj"
      :staffId="manageId"
    ></UploadList>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    UploadList: () => import('@/components/upload/ContractList.vue'),
  },
  inject: ['re'],
  computed: {
    ...mapState({
      outsourceCompanyFileList: (state) => {
        state.partnerCompany.cooperativeCorporationFileList
      },
    }),
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    manageId: {},
    file_Data: {},
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      loading: false,
      saveloading: false,
      uploadData: [],
    }
  },
  watch: {
    manageId: {
      immediate: true,
      handler: function(a) {
        if (a) {
        }
      },
    },
    file_Data: {
      handler: function(a) {
        if (a) {
          this.uploadData = a
        }
      },
    },
    uploadData: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function(newVal) {
        let arr = []
        if (this.uploadData.length > 0) {
          for (let i = 0; i < this.uploadData.length; i++) {
            const e = this.uploadData[i]
            e.fileName = e.attachmentName
            e.filePath = e.attachmentPath
            e.projectId = e.staffId
            e.type = '业主方公司附件'
            arr.push(e)
          }
        }
        this.$emit('change', arr)
      },
    },
  },
  created() {},
  methods: {},
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.el-table {
  height: calc(100vh - 347px);
}
</style>
